import React from 'react';

// Components
import { ConcaveCurve } from 'components/core/curves';
import { Sphere } from 'components/core/sphere';
import { FiMail, FiMapPin } from "react-icons/fi";
import { ContactForm } from 'components/core/contact_form';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Hero(){
  const {t} = useTranslation("contact");
  return <div className="h-full min-h-screen bg-slate-100 lg:pb-32 pt-32 sm:py-32 md:pt-48 md:pb-56 flex items-center justify-center relative overflow-hidden">
          <div className="text-left max-w-7xl lg:px-6 w-full lg:flex lg:space-x-12 space-y-12 lg:space-y-0">
            <div className="px-6 ">
              <h1 className="font-bold font-serif leading-[1.2] tracking-wide text-4xl sm:text-5xl">{t("title")}</h1>
              <p className="opacity-[.75] lg:mt-6 mb-6 text-[20px] lg:text-[26px] mx-auto leading-relaxed">{t("description")}</p>

              <ul className="list-unstyled text-gray-800 space-y-3 text-lg">

              

                <li className="list-inline-item mr-4 flex items-center space-x-3">
                    <FiMail/>
                  <a href="mailto:info@hectiq.ai" className="text-decoration-none mr-auto  hover:opacity-90">
                   info@hectiq.ai
                  </a>
                </li>

                <li className="list-inline-item mr-4 flex items-start space-x-3">
                  <FiMapPin className="mt-1"/>
                  <div>
                    <p> 1444 Av. Maguire, #302</p>
                    <p> Québec, G1T 1Z3 </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="w-full lg:max-w-2xl lg:mx-auto z-50">
                  <div className={"rounded shadow p-6 pb-32 md:p-12 bg-white "} >
                    <div className="text-center">
                      <h2 className="font-serif text-4xl font-bold">{t("form.title")}</h2>
                      <p className="text-slate-500 max-w-md mx-auto text-lg">{t("form.subtitle")}</p>
                    </div>
                    <ContactForm info={"General contact form"}/>
              </div>
            </div> 
          </div>
          <Sphere speed={-0.8} radius={250} offset={0} sphereId="hero-1" color="dark-blue" className=" hidden lg:block max-h-20 left-0 justify-start " svgClassName={""}/>
          <Sphere speed={0.15} radius={500} offset={0} sphereId="hero-3" color="dark-blue" className=" hidden lg:block justify-end top-0" svgClassName={"scale-75 xl:scale-100 xl:translate-y-[12rem] translate-x-3/4 lg:translate-x-1/2"}/>
          <ConcaveCurve color="text-[#354178]"/>
         </div>
}         